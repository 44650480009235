import React, { Component } from "react"
import { Row, Col } from "reactstrap"

import logo from '../../assets/images/212Agency.png'

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={6}>{new Date().getFullYear()} © </Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                consulting agency
                  <img src={logo} style={{ width: "2rem" }} className="ms-3" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer;