import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"


const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        if (props.match.path === "/ystZ13ui4") {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      if (props.match.path === "/ecommerce-cartes/ajouter-carte/your-card") {
        return <Component {...props} />;
      } 
      if (props.match.path === "/ystZ13ui4") {
        return <Component {...props} />;
      }      

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
